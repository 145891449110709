@use "../../sass/colors" as *;

.inputBase {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  border: 1px solid $gray20;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: $white;
  padding: 12px 16px 12px 16px;
  -webkit-user-select: none;
  user-select: none;
  transition: 100ms;
}
