@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.mapLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  transition: 100ms;
  &:hover {
    background-color: $blue00;
    border-radius: 8px;
  }
}

.mapLinkIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background-image: url("../../icons/map.svg");
  transition: 100ms;
  &.checked {
    background-image: url("../../icons/map-active.svg");
  }
}

.mapLink {
  flex: 1 0 0;
  @extend .bodyBoldFont;
  user-select: none;
  transition: 100ms;
}
