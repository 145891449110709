@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.transitionContainer {
  position: absolute;
  z-index: 1000;
  overflow: hidden;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  transition: max-height;
}

.dropdownList {
  background-color: $white;
  border-radius: 8px;
  box-sizing: border-box;
  min-width: 200px;
  padding: 8px 6px;
  outline: none;
  border: 1px solid $gray20;
  pointer-events: auto;
}

.dropdownItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 8px;
  cursor: pointer;
  user-select: none;
  gap: 16px;
  transition: 100ms;
  border-radius: 8px;
  &:hover {
    background-color: $blue00;
  }
}

.itemTitle {
  @extend .bodyRegularFont;
  flex: 1 0 0;
  &.checked {
    color: $blue40;
  }
}

.checkmark {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  &.checked {
    background-image: url("../../icons/ellipseCheck.svg");
  }
}
