.rectangleDiagrammFull {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rectangleDiagramFrame {
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rectangleDiagramLabel {
  display: none;

  max-width: 180px;
  /*
    min-width: 80px;
    */
}

.rectangleDiagramLabelPlashka {
  background-color: white;
  padding: 3px;
}

.rectangleDiagramValue {
  flex: 0;
}

.rectangleDiagrammFull:hover {
  transform: translateX(0px);
}

.rectangleDiagrammFull:hover > .rectangleDiagramLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-wrap: wrap;

  max-width: 180px;
}
