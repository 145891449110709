@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.header {
  @extend .h2Font;
}

.subHeader {
  @extend .h3Font;
}

.description {
  @extend .bodyRegularFont;
  white-space: pre-wrap;
  color: $gray40;
}

.paragraph {
  @extend .bodyRegularLongFont;
  white-space: pre-wrap;
}
