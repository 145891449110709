@use "./sass/colors" as *;

@font-face {
  font-family: "Nunito Sans";
  src: url("./fonts/NunitoSans.ttf");
}

@font-face {
  font-family: "Nunito Sans Black";
  src: url("./fonts/NunitoSans-Black.ttf");
}

:root {
  --default-blue: $blue40;
  --default-font: "Nunito Sans";
  --default-font-black: "Nunito Sans Black";
  --default-font-color: $gray50;
  --header-height: 70px;
  --sidebar-width: 514px;
  --footer-height: 70px;
  --map-controls-top-margin: 20px;
  --map-controls-left-margin: 20px;
  --scrollbar-width: 14px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
  height: 100%;
  font-family: var(--default-font);
  color: var(--default-font-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* Style the scrollbar for Chrome and Safari*/
*::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background-color: $gray00;
}
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-thumb {
  border-radius: 12px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
*::-webkit-scrollbar-thumb {
  background-color: $gray10;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: $gray20;
}

/* Style the scrollbar for Firefox*/
// * {
//   scrollbar-width: thin;
//   scrollbar-color: $gray10 $gray00;
// }
// * {
//   scrollbar-track-color: $gray00;
// }
// * {
//   scrollbar-thumb-color: $gray10;
//   scrollbar-thumb-border-radius: 8px;
// }
// * {
//   scrollbar-thumb-hover-color: $gray20;
// }
