.container {
  width: 700px;
  margin: 0px auto;
  padding: 20px;
  height: 90vh;
  overflow-y: auto;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.85);
}

.heading {
  font-size: 24px;
  margin-bottom: 20px;
}

hr {
  margin: 30px 0;
}

.container label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.wrapper input[type="number"] {
  width: 40%;
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0;
}

.container textarea {
  width: 100%;
}

.containerRight {
  display: flex;
  justify-content: right;
}

.containerBetween {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.modalFormContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 4px;
}
