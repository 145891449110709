.divisionMarkStrip {
  width: var(--gradient-line-width);
  position: absolute;
  z-index: 3;
  height: var(--gradient-line-height);
  display: flex;
  justify-content: space-between;
}

.division {
  background: purple;
  position: relative;
  flex-start: 0;
  width: fit-content;
}

.division:first-child {
  opacity: 0;
}

.divisionMark {
  width: 3px;
  height: 7px;
  background-color: white;
  position: absolute;
  top: 15px;
}
