$base-vh: 0.8vh;

@use "../../sass/colors" as *;

.container {
  position: absolute;
  left: calc((100vw - (103 * $base-vh)) / 3);
  left: calc((100vw - 1180px) / 2);
  top: calc(50vh - (55 * $base-vh) / 2);
  width: 103 * $base-vh;
  height: 55 * $base-vh;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(0.5px);
  border: 1px solid $gray20;
  border-radius: 2 * $base-vh;
  padding: 7 * $base-vh;
  user-select: none;
  pointer-events: auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5.4 * $base-vh;
  width: 90 * $base-vh;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1 * $base-vh;
}

.title {
  color: $blue50;
  font-family: Nunito Sans;
  font-size: 10.4 * $base-vh;
  font-style: normal;
  font-weight: 900;
  line-height: 10.4 * $base-vh;
  text-transform: uppercase;
}

.subTitle {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 3.2 * $base-vh;
  font-style: normal;
  font-weight: 600;
  line-height: 3.6 * $base-vh;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 4 * $base-vh;
}

.button {
  display: flex;
  height: 10 * $base-vh;
  padding: 0 4 * $base-vh;
  justify-content: center;
  align-items: center;
  gap: 2 * $base-vh;
  border-radius: 2.6 * $base-vh;
  background: $blue30;
  box-shadow:
    0px 8px 20px 0px rgba(0, 53, 255, 0.1),
    0px 0px 24px 0px rgba(3, 50, 230, 0.1),
    0px 0px 7px 0px rgba(1, 41, 194, 0.1);
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    background: $blue40;
  }
}

.buttonDemo {
  @extend .button;
  background: $white;
  &:hover {
    background: $gray00;
  }
}

.titleButton {
  color: $white;
  font-family: Nunito Sans;
  font-size: 2.8 * $base-vh;
  font-style: normal;
  font-weight: 600;
  line-height: 2.8 * $base-vh;
}

.titleButtonDemo {
  @extend .titleButton;
  color: $gray40;
}

.iconButton {
  height: 4.7 * $base-vh;
  aspect-ratio: 1/1;
  background-image: url("../../icons/open-button.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
