@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.layersListContainer {
  overflow-y: scroll;
  user-select: none;
  transition: height;
  transition-duration: 500ms;
  &.disableHover {
    pointer-events: none;
  }
  &.disableScroll {
    overflow-y: hidden;
  }
}

.workspaceContainer {
  @extend .layersListContainer;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
  gap: 16px;
  padding: 20px 20px 8px;
}

.title {
  @extend .bodyBoldFont;
  flex: 1 0 0;
  user-select: none;
}

.titleDetailsIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  &:not(.disabled):hover {
    background-image: url("../../icons/details-icon-hover.svg");
  }
  &.disabled {
    cursor: auto;
  }
}

.titleDetailsIconDown {
  @extend .titleDetailsIcon;
  background-image: url("../../icons/details-icon-up.svg");
  transform: rotate(180deg);
}

.titleDetailsIconUp {
  @extend .titleDetailsIcon;
  background-image: url("../../icons/details-icon-up.svg");
  transform: rotate(0deg);
}

.resizer {
  height: 6px;
  background-color: $gray00;
  cursor: row-resize;
  &.active {
    background-color: $gray10;
  }
  &:hover {
    background-color: $gray10;
  }
}
