@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container {
  @extend .flexColumn;
  position: absolute;
  gap: 24px;
  margin-top: 40px;
  width: 780px;
}

.mainTitle {
  @extend .h1Font;
}

.newsContainer {
  @extend .flexColumn;
  gap: 16px;
}

.newsTitleContainer {
  @extend .flexColumn;
  gap: 8px;
}

.newsDate {
  display: flex;
  padding: 2px 8px;
  align-items: baseline;
  gap: 8px;
  border-radius: 4px;
  background: $gray00;
  @extend .bodyBoldFont;
}

.newsTitle {
  @extend .a2SemiboldFont;
  color: $blue40;
}

.contentContainer {
  @extend .flexColumn;
  gap: 24px;
}

.paragraphContainer {
  @extend .flexColumn;
  gap: 16px;
}

.paragraph {
  @extend .bodyRegularFont;
}

.imagesContainer {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.image {
  width: 117px;
  height: 117px;
  border-radius: 8px;
  border-radius: 8px;
}

.iconsContainer {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.icon {
  display: flex;
  width: 184px;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  background: $gray00;
}

.iconTitle {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.iconDescription {
  @extend .bodyBoldFont;
}
