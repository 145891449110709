@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.backdrop {
  background: rgba(26, 32, 74, 0.45);
  backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1601;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalWindow {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: white;
  border-radius: 8px;
  width: 572px;
  max-height: 90vh;
  padding: 4px 0 4px 32px;
  box-shadow: 0 4px 20px 0 rgba(0, 53, 255, 0.15);
  position: relative;
}

.scrollContainer {
  padding: 28px 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 19px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 12px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.titles {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @extend .h2Font;
}

.subtitle {
  @extend .h3Font;
  color: $blue40;
}

.parseHTML {
  @extend .bodyRegularLongFont;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    margin: 0;
    padding: 0;
  }
  span::before {
    content: "•";
    color: black;
    margin-right: 6px;
    display: inline-block;
  }
  span {
    text-indent: -7px;
    margin-left: 20px;
  }
}

.closeButton {
  appearence: none;
  border: none;
  background: none;
  background-image: url(../../icons/cross.svg);
  width: 14px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
}
