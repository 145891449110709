@use "./colors" as *;

/* Accent/A2 Semibold */
.a2SemiboldFont {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

/* Заголовок карты/H1 Black Caps */
.h1Font {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  text-transform: uppercase;
}

/* Подзаголовок карты - слой/H2 */
.h2Font {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

/* Заголовок легенды / графика, текста/H3 */
.h3Font {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

/* Подписи значений легенды / графика , подписи в простых шкалах/Bold */
.bodyBoldFont {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

/* Интерфейсные тексты */
.bodySemiboldFont {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

/* Единицы измерения для любых подзаголовков, интерфейсные тексты */
.bodyRegularFont {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

/* Длинный текст в сайдбаре */
.bodyRegularLongFont {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

/* Подписи осей в сложной легенде / графике  */
.captionSFont {
  color: $gray50;
  font-family: Nunito Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
