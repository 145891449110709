@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.container {
  display: flex;
  flex-direction: column;
  width: 1920px;
  height: 1358px;
  background-color: white;
  padding: 60px 120px;
  gap: 40px;
  box-sizing: border-box;
  border: 1px solid $gray20;
}

.pageTitle {
  @extend .h1Font;
  text-align: center;
}

.postTitle {
  @extend .h2Font;
}

.chapterTitle {
  @extend .bodySemiboldFont;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 1174px;
  width: 100%;
  gap: 40px;
  column-gap: 50px;
  align-content: flex-start;
  overflow: hidden;
}

.dataModule {
  justify-content: space-between;
  width: 526px;
  padding: 20px 32px;
  border: 1px solid $gray20;
  border-radius: 8px;
  box-sizing: border-box;
}

.dataContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 1130px;
  overflow: hidden;
}
