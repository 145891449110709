@use "../../../sass/fonts" as *;
@use "../../../sass/colors" as *;

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}

.mainTitle {
  height: 40px;
  @extend .h1Font;
}

.table {
  width: 100%;
  border-collapse: collapse;
  vertical-align: middle;
}

.table th:first-child {
  width: 160px;
}

.table th:nth-child(3) {
  width: 160px;
}

.table th:nth-child(4) {
  width: 102px;
}

tr {
  border-bottom: 1px solid $gray10;
}

th {
  padding: 16px 0 16px 16px;
}

.arrow {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  &.activeAsc {
    background-image: url(../../../icons/filter-arrow-icon-asc.svg);
  }
  &.activeDesc {
    background-image: url(../../../icons/filter-arrow-icon-desc.svg);
  }
}

.thDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @extend .bodyBoldFont;
  border-right: 1px solid $gray10;
  text-align: left;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: $gray40;
  }
}

.thLastDiv {
  @extend .bodyBoldFont;
  padding-right: 16;
  cursor: default;
}

td {
  @extend .bodyRegularFont;
  padding: 16px;
  vertical-align: middle;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
