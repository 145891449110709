.screenLimit {
  width: 100vw;
  headers: 100vh;
}

.screenLimitContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Arial;
}

.splashScreen {
  background-image: url("../assets/images/splash.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.splashScreenOpacity {
  @extend .splashScreen;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("../assets/images/splash.webp");
}

.contentBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
