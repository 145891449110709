@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.elemItem {
  padding: 8px 20px;
}

.elementContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  user-select: none;
  cursor: grab;
  &.grabbing {
    cursor: grabbing;
  }
}

.titleContainer {
  @extend .bodyRegularFont;
  flex: 1 0 0;
  margin-top: 2px;
}

.title {
  @extend .bodyBoldFont;
  display: block;
}

.chapter {
  color: $gray40;
  display: block;
}

.detailsIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  transition: transform 0.5s ease;
  cursor: pointer;
  &:hover {
    background-image: url("../../icons/details-icon-hover.svg");
  }
}

.detailsIconDown {
  @extend .detailsIcon;
  background-image: url("../../icons/details-icon-up.svg");
  transform: rotate(180deg);
}

.detailsIconUp {
  @extend .detailsIcon;
  background-image: url("../../icons/details-icon-up.svg");
  transform: rotate(0deg);
}
