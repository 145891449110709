@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.statusContainer {
  @extend .bodyRegularFont;
  border-radius: 2px;
  padding: 1px 8px;
  width: fit-content;
  white-space: nowrap;
  user-select: none;
  &.green {
    border: 1px solid $green20;
    background: $green00;
    color: #52c41a;
  }
  &.blue {
    border: 1px solid $blue20;
    background: $blue00;
    color: #2f54eb;
  }
  &.red {
    border: 1px solid $red20;
    background: $red00;
    color: $red50;
  }
  &.gray {
    border: 1px solid $gray20;
    background: $gray00;
    color: $gray40;
  }
}
