@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.button {
  @extend .bodySemiboldFont;
  color: white;
  background: $blue30;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.1s;
  width: fit-content;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  &.medium {
    padding: 10px 24px;
  }
  &.large {
    padding: 14px 24px;
  }
  &:hover {
    background-color: $blue40;
  }
  &:active {
    background-color: $blue50;
  }
}

.disabled {
  background-color: $gray30;
  &:hover {
    background-color: $gray30;
  }
}
