@use "../../sass/colors" as *;

.plusMinusButton {
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
  flex-shrink: 0;
  &.open {
    background-image: url("../../icons/button-minus.svg");
  }
  &.close {
    background-image: url("../../icons/button-plus.svg");
  }
}
