@use "../../../sass/fonts" as *;
@use "../../../sass/colors" as *;

.container {
  position: relative;
}

.selectContainer {
  composes: inputBase from "../inputBase.module.scss";
  cursor: pointer;
}

.selectContainer:not(.selectContainerOpen):hover {
  background-color: $blue00;
  border-radius: 8px;
  border: 1px solid $blue20;
}

.selectContainerOpen {
  @extend .selectContainer;
  outline: none;
  border: 1px solid $white;
}

.input {
  @extend .bodyBoldFont;
  width: 390px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roundArrowDown {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-image: url("../../../icons/round-arrow-down.svg");
  transition: background-image 0s 0.5s ease;
}

.roundArrowUp {
  @extend .roundArrowDown;
  background-image: url("../../../icons/round-arrow-up.svg");
}

.dropdownInitial {
  display: none;
}

.dropdownOpen {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  z-index: 4;
  box-shadow:
    0px 8px 20px 0px rgba(0, 53, 255, 0.1),
    0px 0px 24px 0px rgba(3, 50, 230, 0.1),
    0px 0px 7px 0px rgba(1, 41, 194, 0.1);

  overflow: hidden;
  animation-name: slideDown;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  from {
    max-height: 48px;
  }
  to {
    max-height: 517px;
  }
}

.dropdown {
  @extend .dropdownOpen;
  animation-name: slideUp;
  animation-duration: 0.5s;
}

@keyframes slideUp {
  from {
    max-height: 517px;
  }
  to {
    max-height: 48px;
    display: none;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  border-top: 1px solid $gray00;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  color: $gray50;
  padding: 14px 10px 14px 8px;
  cursor: pointer;
  user-select: none;
  gap: 16px;
  transition: 100ms;
}

.itemTitle {
  flex: 1 0 0;
  @extend .bodySemiboldFont;
}

.itemTitleChecked {
  @extend .itemTitle;
  color: $blue40;
}

.checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.checked {
  @extend .checkbox;
  background-image: url("../../../icons/ellipseCheck.svg");
}

.emptyListItem {
  @extend .listItem;
  cursor: default;
  color: $gray30;
}

.listItem:hover {
  background-color: $blue00;
  border-radius: 8px;
}
