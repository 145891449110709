@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.button {
  border-radius: 8px;
  background: $white;
  border: 1px solid $gray20;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.1s ease;
}

.button:hover {
  background-color: $blue00;
}

.zoomHomeButton {
  @extend .button;
  z-index: 1;
  position: absolute;
  bottom: 104px;
  left: 16px;
  width: 32px;
  height: 32px;
  background-image: url("../../icons/zoom-home.svg");
}

.zoomHomeButton:hover {
  background-image: url("../../icons/zoom-home-hover.svg");
}

.buttonsContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 16px;
  z-index: 1;
  top: 16px;
  left: 16px;
}

.searchButton {
  @extend .button;
  width: 40px;
  height: 40px;
  background-image: url("../../icons/search.svg");
}

.burgerMenuButton {
  @extend .button;
  width: 40px;
  height: 40px;
  background-image: url("../../icons/burger-menu.svg");
}

.burgerMenuButtonOpen {
  @extend .burgerMenuButton;
  background-color: $gray00;
}

.dropdown {
  z-index: 4;
  position: absolute;
  transform: translateY(8px);
  background-color: $white;
  border-radius: 8px;
  box-sizing: border-box;
  width: 200px;
  padding: 8px 6px;
  outline: none;
  border: 1px solid $gray20;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  pointer-events: auto;
}

.dropdownOpen {
  @extend .dropdown;
  max-height: 500px;
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.5s ease;
}

.dropdownClose {
  @extend .dropdown;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition:
    max-height 0.5s ease,
    opacity 0s 0.38s ease;
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 8px;
  cursor: pointer;
  user-select: none;
  gap: 16px;
  transition: 100ms;
}

.listItem:hover {
  background-color: $blue00;
  border-radius: 8px;
}

.itemTitle {
  flex: 1 0 0;
  @extend .bodyRegularFont;
}

.itemTitleChecked {
  @extend .itemTitle;
  color: $blue40;
}

.checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.checked {
  @extend .checkbox;
  background-image: url("../../icons/ellipseCheck.svg");
}

.container {
  background: transparent;
  z-index: 1;
  position: absolute;
  top: calc(var(--map-controls-top-margin));
  left: var(--map-controls-left-margin);
}

.overlayButton {
  margin: 5px;
  pointer-events: auto;
  padding: 3px;
  min-width: 50px;
  height: 50px;
  overflow: hidden;
}
