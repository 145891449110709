.selectorScale {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

.button {
  width: 64px;
  height: 14px;
  margin-right: 4px;
  border-radius: 3px;
  cursor: pointer;
}

.button:hover {
  outline: 1px solid rgb(182, 179, 179);
}

.labelContainer {
  display: flex;
  flex-direction: row;
  margin-left: 34px;
}

.label {
  width: 64px;
  height: 16px;
  margin-right: 5px;
  text-align: center;
  font-weight: bold;
}
