@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.header {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  z-index: 2;
}

.container {
  width: 1180px;
}

.navbar {
  display: flex;
  align-items: flex-start;
  gap: 40px;
}

.linkContainer {
  text-decoration: none;
  display: flex;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover .linkTitle {
    color: $blue40;
  }
}

.linkContainerActive {
  @extend .linkContainer;
  .linkTitle {
    @extend .linkTitle;
    color: $blue40;
  }
  .linkLineActive {
    position: absolute;
    bottom: -12px;
    height: 2px;
    width: 100%;
    background: $blue40;
    animation-name: slideDown;
    animation-duration: 0.3s;
  }
}

@keyframes slideDown {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.linkTitle {
  @extend .bodySemiboldFont;
  transition: 100ms;
}
