@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.container {
  display: flex;
  gap: 8px;
  position: relative;
  flex-wrap: wrap;
}

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background: $gray00;
  border-radius: 8px;
  border: 1px solid transparent;
  @extend .bodyBoldFont;
  color: $gray40;
  user-select: none;
}

.interactive {
  @extend .tag;
  cursor: pointer;
  &:hover {
    border: 1px solid $gray20;
    color: $gray50;
  }
}

.interactiveSelected {
  background: $white;
  border: 1px solid $gray20;
  color: $gray50;
}

.tagText {
}
