@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.menu {
  padding: 20px;
}

.menuDisable {
  @extend .menu;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

.menuConstructor {
  border-bottom: 1px solid $gray10;
  padding: 20px 20px 0 20px;
}

.headerMenu {
  @extend .h1Font;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 24px;
  user-select: none;
}

.chaptersTitle {
  @extend .bodyRegularFont;
  color: $gray40;
  padding: 24px 0 10px 0;
  user-select: none;
}

.notSelected {
  @extend .bodyRegularFont;
  color: $gray40;
  text-align: center;
  padding-top: 35vh;
  user-select: none;
}

.storyContainer {
  width: 514px;
  position: absolute;
  right: 0;
  border: 1px solid $gray50;
  border-radius: 4px;
  height: 100%;
}
