.main {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  top: 64px;
  height: calc(100vh - 64px);
  overflow-y: scroll;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 1180px;
}
