@use "../../sass/colors" as *;

.container {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  border: 1px solid $gray10;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  background-color: $white;
  flex-shrink: 0;
  position: absolute;
}

.container input:checked ~ .checkmark {
  background-color: $blue30;
  border: 1px solid $blue30;
}

.checkmark:after {
  content: "";
  position: relative;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
