@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.lockMapLinkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  transition: 100ms;
}

.mapLinkContainer {
  @extend .lockMapLinkContainer;
  &:hover {
    background-color: $blue00;
    border-radius: 8px;
  }
}

.iconBase {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  transition: 100ms;
}

.mapLinkIcon {
  @extend .iconBase;
  background-image: url("../../icons/map.svg");
  &.checked {
    background-image: url("../../icons/map-active.svg");
  }
  &.lockIcon {
    background-image: url("../../icons/lock.svg");
  }
  &.docsIcon {
    background-image: url("../../icons/docs.svg");
  }
}

.mapLink {
  flex: 1 0 0;
  @extend .bodyRegularFont;
  user-select: none;
  transition: 100ms;
}

.lockLink {
  @extend .mapLink;
  color: $gray20;
}

.download {
  @extend .iconBase;
  background-image: url("../../icons/download.svg");
  &:hover {
    background-image: url("../../icons/download-active.svg");
  }
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid $gray20;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 20px 0 rgba(0, 53, 255, 0.1);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 278px;
  transition: 0.1s;
}

.tooltipContainerDisabled {
  @extend .tooltipContainer;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
}

.title {
  @extend .bodyBoldFont;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
