@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.menuButtonContainer {
  width: fit-content;
}

.menuButton {
  border-radius: 8px;
  background: $white;
  border: 1px solid $gray20;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.1s ease;
  width: 40px;
  height: 40px;
  &:hover {
    background-color: $blue00;
  }
}

.menuButtonOpen {
  background-color: $gray00;
}

.burgerMenu {
  background-image: url("../../icons/burger-menu.svg");
}

.burgerMenuOpen {
  background-image: url("../../icons/burger-menu-active.svg");
}

.userMenu {
  background-image: url("../../icons/user.svg");
}

.userMenuOpen {
  background-image: url("../../icons/user-active.svg");
}
