@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.miniSidebar {
  position: absolute;
  z-index: 1;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-shrink: 0;
  gap: 12px;
  padding: 16px;
  top: 16px;
  width: 400px;
  border-radius: 8px 0 0 8px;
  background: $white;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: $gray20;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  pointer-events: auto;
  transition: 0.3s;
}

.miniSidebarOpen {
  @extend .miniSidebar;
  animation-name: slideOpen;
}

@keyframes slideOpen {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.miniSidebarClose {
  @extend .miniSidebar;
  animation-name: slideClose;
}

@keyframes slideClose {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.miniSidebarTitleContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.miniSidebarTitle {
  @extend .h1Font;
  text-wrap: nowrap;
}

.miniSidebarSubTitle {
  @extend .bodyRegularFont;
  color: $gray40;
}

.miniSidebarIcon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.clusterTitle {
  @extend .bodyBoldFont;
  display: inline-flex;
  padding: 3px 8px;
  align-items: baseline;
  gap: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
  background: $blue00;
  color: $blue30;
  user-select: none;
}

.chapterTitle {
  @extend .bodyRegularFont;
  align-self: stretch;
  color: $gray40;
}

.mapTitle {
  @extend .bodyBoldFont;
  padding-top: 4px;
}
