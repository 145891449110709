@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 24px;
  margin-top: 40px;
  padding-bottom: 40px;
  width: 1180px;
}

.menu {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  padding-left: 10px;
  border-bottom: 1px solid $gray10;
  margin-bottom: 8px;
  position: relative;
}

.menuButton {
  @extend .bodySemiboldFont;
  cursor: pointer;
  user-select: none;
  border-top: 1px solid $gray10;
  border-left: 1px solid $gray10;
  border-right: 1px solid $gray10;
  padding: 8px 12px;
  border-radius: 8px 8px 0 0;
  background-color: $gray00;
  position: relative;
  z-index: 1;

  &.active {
    background-color: transparent;
    z-index: 2;
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
  }
}
