:root {
  --gradient-line-width: 420px;
  --gradient-line-height: 20px;
  --numbers-line-width: 450px;
}

.diagramContainer {
  width: 100%;
  max-width: 500px;
  position: relative;
}

.gradientLine {
  background: linear-gradient(to right, yellow, red);
  width: var(--gradient-line-width);
  height: var(--gradient-line-height);
  margin: auto;
}

.numbersContainer {
  display: inline-flex;
  justify-content: space-between;
  width: var(--numbers-line-width);
}

.numbersContainer > div {
  text-align: center;
  position: relative;
  flex-start: 0;
  margin: 5 auto;
  width: fit-content;
}
