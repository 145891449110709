@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container {
  @extend .flexColumn;
  gap: 16px;
  width: 780px;
}

.titleContainer {
  @extend .flexColumn;
  gap: 8px;
}

.title {
  @extend .a2SemiboldFont;
  color: $blue40;
}

.date {
  display: flex;
  padding: 2px 8px;
  align-items: baseline;
  gap: 8px;
  border-radius: 4px;
  background: $gray00;
  @extend .bodyBoldFont;
}

.contentContainer {
  @extend .flexColumn;
  @extend .bodyRegularFont;
  gap: 8px;
  p {
    margin: 0;
    padding: 0;
  }
  span::before {
    content: "•";
    color: black;
    margin-right: 6px;
    display: inline-block;
  }
  span {
    text-indent: -7px;
    margin-left: 20px;
  }
}
