@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.contentContainer {
  border: 1px solid $gray20;
  width: 502px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  @extend .h3Font;
}
