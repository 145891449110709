@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.flexCenterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
}

.pageContainer {
  @extend .flexCenterContainer;
  height: 100vh;
}

.authModule {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  padding: 56px;
  width: 512px;
  gap: 32px;
  backdrop-filter: blur(1px);
  background: rgba(255, 255, 255, 0.8);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  @extend .h1Font;
  user-select: none;
}

.back {
  @extend .bodySemiboldFont;
  border: none;
  background-color: transparent;
  color: $gray40;
  cursor: pointer;
  right: 0;
  &:hover {
    color: $blue40;
  }
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fieldTitle {
  @extend .bodySemiboldFont;
  user-select: none;
}

.fieldText {
  @extend .bodyRegularFont;
  user-select: none;
  text-align: center;
}

.fieldNotice {
  @extend .bodyRegularFont;
  user-select: none;
  text-align: center;
  color: $gray40;
}

.forgotContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerLink {
  @extend .bodyRegularFont;
  color: $blue30;
  cursor: pointer;
  user-select: none;
}

.confirmIcon {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../icons/round-arrow-green.svg");
}

.confirmationTitleContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
}

.confirmationTitle {
  @extend .h3Font;
}

.confirmationText {
  @extend .bodyRegularFont;
}
