@use "../../../sass/colors" as *;
@use "../../../sass/fonts" as *;

.inputContainer {
  position: relative;
  margin: 0 0 8px;
}

.eyeIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  appearance: none;
  cursor: pointer;
  appearance: none;
  border: 0;
  background-image: url("../../../icons/eye-icon-open.svg");
  transition: all 0.1s ease-in-out;
}

.eyeIconClose {
  background-image: url("../../../icons/eye-icon-close.svg");
}

.notValid {
  border-color: $red40;
}

.disabled {
  background-color: $gray00;
  border: 1px solid $gray10;
}

.validationMessage {
  @extend .bodyRegularFont;
  color: $red40;
  padding-top: 4px;
}
