@use "../../sass/fonts" as *;
@use "../../sass/colors" as *;

.tagListColumns {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.columnContainer100 {
  composes: columnContainer;
  width: 100%;
}

.tagList {
  display: flex;
  flex-direction: column;
}

.tagContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: inline-block;
}

.title {
  @extend .bodyBoldFont;
  word-break: break-word;
}
