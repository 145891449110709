@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.sidebar {
  pointer-events: auto;
  width: var(--sidebar-width);
  z-index: 1;
  position: absolute;
  height: 100%;
  animation-name: slideOpen;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  will-change: transform;
  box-shadow: 0px 4px 20px 0px rgba(10, 31, 112, 0.1);
  backdrop-filter: blur(10px);
  transition: box-shadow 1s ease;
  background-color: rgba(255, 255, 255, 0.9);
}

@keyframes slideOpen {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.sidebarClosed {
  right: -calc(var(--sidebar-width));
  animation-name: slideClose;
  box-shadow: none;
}

@keyframes slideClose {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.scrollContent {
  display: flex;
  flex-direction: column;

  height: 100vh;
  overflow-y: scroll;
  position: relative;
}

.titlesContainer {
  padding: 20px;
  border-bottom: 1px solid $gray20;
}

.clusterTitle {
  @extend .bodyBoldFont;
  display: inline-flex;
  padding: 3px 8px;
  align-items: baseline;
  gap: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
  background: $blue00;
  color: $blue30;
  user-select: none;
}

.chapterTitle {
  @extend .bodySemiboldFont;
  align-self: stretch;
  color: $gray40;
}

.mapTitle {
  @extend .h1Font;
  padding-top: 8px;
}

.content {
  display: flex;
  flex-direction: column;
}

.foggyLayer {
  width: calc(100% - var(--scrollbar-width));
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  right: var(--scrollbar-width);
}

.foggyAreaBase {
  height: 20px;
}

.foggyAreaTop {
  @extend .foggyAreaBase;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.foggyAreaBottom {
  @extend .foggyAreaBase;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.button {
  position: absolute;
  z-index: 1;
  right: calc(var(--sidebar-width) + 16px);
  border-radius: 8px;
  border: 1px solid $gray20;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: center;
  appearance: none;
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.button:hover {
  background-color: $blue00;
}

.closeButton {
  @extend .button;
  top: 32px;
  transition:
    background-color 0.1s ease,
    background-image 0s 1s;
}

.closeButtonOpen {
  @extend .closeButton;
  background-image: url("../../icons/menu-open.svg");
}

.closeButtonClosed {
  @extend .closeButton;
  background-image: url("../../icons/menu-close.svg");
}

.returnButton {
  @extend .button;
  top: 84px;
  background-image: url("../../icons/arrow-back.svg");
  pointer-events: auto;
  opacity: 1;
  transition:
    background-color 0.1s ease,
    opacity 0.5s;
}

.returnButtonNone {
  @extend .button;
  top: 84px;
  background-image: url("../../icons/arrow-back.svg");
  opacity: 0;
  pointer-events: none;
  user-select: none;
  cursor: none;
  transition:
    background-color 0.1s ease,
    opacity 0.3s;
}

.postVisible {
  display: block;
  position: absolute;
  width: 100%;
}

.postHidden {
  display: none;
}
