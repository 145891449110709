.button {
  position: absolute;
  z-index: 1;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #bcbfcc;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  pointer-events: auto;
  transition: 0.1s ease;
  &:hover {
    background-color: #edf4fd;
  }
}

.screenshotButton {
  @extend .button;
  width: 40px;
  height: 40px;
  background-image: url("../../icons/screenshot.svg");
  top: 16px;
  left: 184px;
  &.loading {
    background-color: #edf4fd;
    background-image: url("../../icons/screenshot-active.svg");
  }
}
