$white: #fff;

$gray50: #272d34;
$gray40: #626673;
$gray30: #9599a6;
$gray20: #bcbfcc;
$gray10: #d8dceb;
$gray00: #f0f1f5;

$blue50: #0a1f70;
$blue40: #0526a1;
$blue30: #3d57b2;
$blue20: #85a4d0;
$blue10: #cedef5;
$blue00: #edf4fd;

$red50: #c22538;
$red40: #e4344a;
$red30: #ff556a;
$red20: #f4a6af;
$red00: #f7d9d0;

$green50: #5f953b;
$green40: #76bc48;
$green30: #92d962;
$green20: #b6f28d;
$green00: #f6ffed;
