@use "../../../sass/colors" as *;
@use "../../../sass/fonts" as *;

.container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  width: 100%;
}

.element {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  height: 20px;
  background-color: $gray00;
}

.title {
  @extend .bodyBoldFont;
}
