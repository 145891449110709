@use "../../../sass/colors" as *;

.dynamicGradient {
  background: linear-gradient(
    to right,
    $gray10 0%,
    $gray00 15%,
    $gray00 85%,
    $gray10 100%
  );
  background-size: 200% 100%;
  animation: gradientAnimation 1s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.titlesContainer {
  padding: 20px;
  border-bottom: 2px solid $gray00;
}

.clusterTitle {
  height: 26px;
  width: 60px;
  border-radius: 4px;
  margin-bottom: 12px;
  background: linear-gradient(
    to right,
    $blue10 0%,
    $blue00 30%,
    $blue00 60%,
    $blue10 100%
  );
  background-size: 200% 100%;
  animation: gradientAnimation 1s linear infinite;
}

.chapterTitle {
  height: 20px;
  width: 300px;
  border-radius: 4px;
  @extend .dynamicGradient;
}

.mapTitleContainer {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.mapTitle {
  height: 22px;
  border-radius: 4px;
  @extend .dynamicGradient;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;
}

.subBlock {
  height: 40px;
  border-radius: 4px;
  @extend .dynamicGradient;
}

.legends {
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.legend {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.icon {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background: $gray00;
}

.legendTitle {
  height: 20px;
  border-radius: 4px;
  @extend .dynamicGradient;
}
