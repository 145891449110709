@use "../../../sass/colors" as *;
@use "../../../sass/fonts" as *;

.columnContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.dataContainer {
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: space-between;
  width: 100%;
}

.element {
  flex: 1 0 0;
  height: 16px;
  background-color: $gray00;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 32px;
}

.label {
  @extend .bodyBoldFont;
  min-width: 64px;
  text-align: center;
  user-select: none;
}
