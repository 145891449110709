@use "../../sass/fonts" as *;

.container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.title {
  @extend .bodyRegularFont;
}

.icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  &.disabled {
    background-image: url("../../icons/round-arrow-gray.svg");
  }
  &.success {
    background-image: url("../../icons/round-arrow-green.svg");
  }
  &.wrong {
    background-image: url("../../icons/round-arrow-red.svg");
  }
}
