@use "../../../sass/colors" as *;
.animatedGradient {
  background: repeating-linear-gradient(
    to right,
    $blue40 0%,
    white 50%,
    $blue40 100%
  );
  width: 100%;
  height: 8px;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  position: absolute;
  z-index: 3;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
