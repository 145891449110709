:root,
:host { 
  --ol-brand-color: #ff00ee !important;
}

.mapWrap {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.main {
  z-index: 1;
  flex: 1 0 auto;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: row;
}

.containerLeft {
  background-color: transparent;
  flex: 1;
}

.containerRight {
  background-color: transparent;
  position: relative;
  width: fit-content;
}

.footer {
  background-color: var(--default-blue);
  z-index: 1;
  flex: 0 0 var(--footer-height);
}

.welcomH1 {
  color: var(--default-blue);
  font-size: 9rem;
  text-align: left;
  margin-left: 10%;
  margin-top: 6%;
  margin-bottom: 2%;
  line-height: 1;
  font-weight: 800;
}

.welcomSubHeader {
  background-color: white;
  text-align: left;
  margin-left: 10%;
  font-size: 3rem;
  line-height: 1;
  font-weight: 900;
  padding: 30px 50px;
  width: fit-content;
}

.zoom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  bottom: 32px;
  left: 16px;
  width: 32px;
  height: 64px;
  background-color: transparent !important;
  box-shadow: 0px 4px 20px 0px rgba(0, 53, 255, 0.1);
}

.zoom button {
  width: 32px !important;
  height: 32px !important;
  margin: auto !important;
  outline: none !important;
  font-size: 0 !important;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: 0.1s ease;
}

.zoom button:first-child {
  border-radius: 8px 8px 0px 0px;
  border: 1px solid var(--gray20, #bcbfcc);
  border-bottom: none;
  background-image: url("../../icons/zoom-in.svg");
}

.zoom button:first-child:hover {
  background-color: #edf4fc;
  background-image: url("../../icons/zoom-in-hover.svg");
}

.zoom button:last-child {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid var(--gray20, #bcbfcc);
  background-image: url("../../icons/zoom-out.svg");
}

.zoom button:last-child:hover {
  background-color: #edf4fc;
  background-image: url("../../icons/zoom-out-hover.svg");
}

@media (max-width: 1200px) {
  .welcomH1 {
    font-size: 5rem;
  }
  .welcomSubHeader {
    font-size: 2rem;
  }
}

@media (max-width: 650px) {
  .welcomH1 {
    display: none;
  }
  .welcomSubHeader {
    display: none;
  }
}

.plashkaMountains {
  display: flex;
  flex-direction: row;
}

.mountainName {
  background-color: black;
  color: white;
  padding: 2px 6px 2px 6px;
  font-weight: 700;
}

.mountainH {
  background-color: white;
  color: red;
  padding: 2px 6px 2px 6px;
  font-weight: 900;
}

.plashkaClusterAbbr {
  background-color: #0334b9;
  color: white;
  padding: 2px 6px 2px 6px;
  font-weight: 700;
  width: auto;
}

.plashkaClusterUniName {
  background-color: black;
  color: white;
  padding: 2px 6px 2px 6px;
  font-size: 10px;
  max-width: 150px;
  min-width: 80px;
  font-weight: 700;
  flex-basis: 25%;
}

.clusterFrameName {
  display: flex;
  flex-direction: row;
  padding-left: 120px;
}

.clusterFrame {
  width: 120px;
  height: 120px;
  background-color: transparent;
  color: red;
  stroke-width: 5px;
  border: 3px solid #0334b9;
  margin-right: 2px;
}

.clusterName {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-wrap: wrap;
}

.customPopup {
  margin-left: 10px;
  margin-top: -10px;
}

.customPopupPlashka {
  margin-left: 10px;
}

.framePopup {
  max-width: 300px;
  max-height: 250px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.framePopupDiagram {
  width: 200px;
  max-height: 300px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.titlePopup {
  color: black;
  text-transform: uppercase;
  font-weight: 800;
}

.titleContentText {
  color: black;
}

.buttonReadMore {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
