@use "../../sass/colors" as *;
@use "../../sass/fonts" as *;

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  user-select: none;
}

.listMenuContainer {
  display: flex;
  flex-direction: column;
  background: $white;
  border: 1px solid $gray20;
  border-radius: 8px;
  &:hover {
    border: 1px solid $blue20;
  }
}

.chapterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  padding: 14px 16px 14px 16px;
  gap: 16px;
  transition: 100ms;
  &:hover {
    background-color: $blue00;
    border-radius: 8px;
    .detailsIconDown {
      background-image: url("../../icons/details-icon-hover.svg");
    }
    .detailsIconUp {
      background-image: url("../../icons/details-icon-hover.svg");
    }
  }
}

.chapterContainerOpen {
  @extend .chapterContainer;
  &:hover {
    border-radius: 8px 8px 0 0;
  }
}

.clusterText {
  flex: 1 0 0;
  @extend .bodyBoldFont;
}

.lockСlusterText {
  flex: 1 0 0;
  @extend .bodyBoldFont;
  color: $gray30;
}

.detailsIconDown {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background-image: url("../../icons/details-icon-up.svg");
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.detailsIconUp {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background-image: url("../../icons/details-icon-up.svg");
  transition: transform 0.5s ease;
  transform: rotate(0deg);
}

.transition {
  overflow: hidden;
  transition: max-height;
}

.listContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 12px 8px 12px;
}
